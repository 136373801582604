.upload-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .upload-btn {
    background-color: #6200ea;
    color: #fff;
    font-size: 16px;
    text-transform: none;
    padding: 10px 20px;
    border-radius: 30px;
    transition: background-color 0.3s ease;
  }
  
  .upload-btn:hover {
    background-color: #3700b3;
  }
  
  .upload-btn .MuiButton-startIcon {
    margin-right: 8px;
  }

  .dashboard-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .date-picker-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .summary-section {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .summary-section p {
    font-size: 1.2em;
  }
  
  .chart-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .chart-item {
    background: #fff;
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    height: 400px;
    position: relative;
  }
  
  .details-section {
    margin-top: 40px;
    background: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
  }
  
  .details-section p {
    font-size: 1.1em;
    line-height: 1.6;
  }
  